<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
      <div v-if="block.options.images.length">
        <small>({{ block.options.images.length }} attachments)</small>
      </div>
    </div>

    <b-modal id="image-upload-modal" v-model="isShowModal" title="Image" size="lg" @shown="manuallyAddFile(block.options.images)">
      <TextInput v-model="block.name" label="Label" />

      <p class="text-primary font-weight-bold">Maximum image size is 5MB</p>
      <vue-dropzone
        :id="vid"
        ref="dropzoneUpload"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        @vdropzone-sending="sendingFile"
        @vdropzone-removed-file="onFileRemoved"
        @vdropzone-complete="onComplete"
        @vdropzone-processing="onFileProcessing"
        @vdropzone-error="onUploadFailed"
      >
        <div class="dropzone-custom-content">
          <i class="h1 text-muted uil-cloud-upload"></i>
          <h3 class="dropzone-custom-title">
            Drag & Drop or Click To Choose A File To Upload.
          </h3>
        </div>
      </vue-dropzone>

      <template v-slot:modal-footer>
        <b-button v-if="block.options.images.length" variant="danger" @click="removeAllFiles">
          Remove Files 
        </b-button>
        <b-button v-if="block.options.images.length" variant="primary" @click="save">
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
      keywords: [],
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      dropzoneOptions: {
        maxFilesize: 5,
        maxFiles: 10,
        // acceptedFiles:
        //   "image/jpeg, image/gif, image/png, audio/basic, audio/L24, audio/mp4, audio/mpeg ,audio/ogg, audio/vorbis, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr, video/mpeg, video/mp4, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/3gpp-tt, video/H261, video/H263, video/H263-1998, video/H263-2000, video/H264, image/jpeg, image/gif, image/png, image/bmp, text/vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, application/pdf",
        url: `${process.env.VUE_APP_BASE_API}api/v1/uploads/image`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    vid() {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
    },
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    sendingFile() {},

    manuallyAddFile(files) {
      files.forEach((file) => {
        var url = file.path;
        this.$refs.dropzoneUpload.manuallyAddFile(file, url);
      });
    },

    removeAllFiles() {
      this.$refs.dropzoneUpload.removeAllFiles()
      this.block.options.images = []
    },

    onFileRemoved(file) {
    },

    onComplete(res) {
      if (res.status === "success") {
        this.block.options.images.push(JSON.parse(res.xhr.response)["data"])
      }
    },

    onFileProcessing() {
      this.processing = true;
    },

    showModal() {
      this.isShowModal = true
    },

    save() {
      this.isShowModal = false
    },

    onUploadFailed(file, message) {
      if (message) {
        let content = message
        if (message.errors && message.errors.file) {
          content = message.errors.file[0]
        }
        this.$toast.open({
          message: content,
          type: 'error',
        })
      }

      this.$refs.dropzoneUpload.removeFile(file)
    }
  },
}
</script>